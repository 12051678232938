<template>
  <aside class="py-2xs w-full">
    <Stack tag="header" gap="xs" class="p-xs border-b border-b-sm w-full border-grey-light cursor-default">
      <Stack
        tag="div"
        align="center"
        justify="center"
        class="h-[48px] w-[48px] bg-mkm-blue-default rounded-round text-white text-2xl font-semibold"
      >
        {{ userInitials }}
      </Stack>

      <Stack tag="div" align="center" justify="center" direction="col" gap="none">
        <Text tag="p" align="left" weight="bold">{{ user?.profile.first_name }} {{ user?.profile.last_name }}</Text>
        <Text tag="p" align="left" size="caption">{{ user?.email }}</Text>
      </Stack>
    </Stack>

    <nav>
      <ul>
        <li class="h-full w-full hover:bg-grey-default text-left" v-for="menuItem in menuItems" :key="menuItem.key">
          <NuxtLink :to="menuItem.href" class="block w-full h-full px-xs py-[12px]" @click="handleItemClick">
            <Text tag="span" weight="semi" align="left">{{ menuItem.label }}</Text>
          </NuxtLink>
        </li>
        <li class="h-full w-full hover:bg-grey-default text-left">
          <button class="block w-full h-full px-xs py-[12px] text-left" @click="handleSignOut">
            <Text tag="span" weight="semi" align="left" class="text-red-dark">Sign out</Text>
          </button>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script lang="ts" setup>
import { MyAccountMenuItem } from "~/components/MyAccount/MyAccountMenu/myAccountMenuTypes";
import { getMyAccountMenuItems } from "~/components/MyAccount/MyAccountMenu/myAccountMenuItems";
import { ref } from "vue";

const emit = defineEmits(["close"]);

const { user, logout, isAdmin, isCredit } = useUser();
const menuItems = ref<MyAccountMenuItem[]>([]);

const handleSignOut = async () => {
  await logout();
  emit("close");
};

const userInitials = computed(() => {
  if (user.value) return user.value.profile.first_name.charAt(0) + user.value.profile.last_name.charAt(0);
});

const handleItemClick = (e: any) => {
  e.stopPropagation();
  emit("close");
};

watch(
  user,
  () => {
    if (user.value) {
      menuItems.value = getMyAccountMenuItems(isAdmin.value, isCredit.value);
    }
  },
  { immediate: true },
);
</script>
